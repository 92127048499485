<template>
  <div class="warehouse-container">
    <div class="warehouse-title">入仓单管理</div>
    <div class="queryItems mb20">
      <div class="u-start-flex mr15">
        <div class="default-font">入仓单号：</div>
        <el-input v-model="query.OrderCode" size="small" class="mr15" clearable style="width: 20rem" placeholder="SKU编码"></el-input>
      </div>
      <div class="u-start-flex mr15">
        <div class="default-font">订单状态：</div>
        <el-select size="small" v-model="query.GoodsTypeId" clearable style="width: 20rem">
          <el-option
              v-for="item in warehouseOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div class="u-start-flex mr15">
        <div class="default-font">所属仓库：</div>
        <el-select size="small" v-model="query.WareHouseId" clearable style="width: 20rem">
          <el-option
              v-for="item in warehouseOptions"
              :key="item.Id"
              :label="item.Name"
              :value="item.Id">
          </el-option>
        </el-select>
      </div>
      <div class="u-start-flex mr15">
        <div class="default-font">下单时间：</div>
        <el-date-picker
            size="small"
            v-model="query.dateRange"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始时间"
            end-placeholder="结束时间">
        </el-date-picker>
      </div>
      <el-button type="primary" class="" size="small" icon="el-icon-search" @click="getData">查询</el-button>
    </div>
    <el-table
        :data="listData"
        size="mini"
        border
        style="width: 100%"
        v-loading="loading"
    >
      <el-table-column label="入仓单号" prop="OrderCode" align="center"></el-table-column>
      <el-table-column label="仓库" prop="WareHouseName" align="center"></el-table-column>
      <el-table-column label="申请数量" prop="Quantity" align="center"></el-table-column>
      <el-table-column label="实到数量" prop="StorageQuantity" align="center"></el-table-column>
      <el-table-column label="创建时间" prop="CreateTime" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.CreateTime | formatDate }}</span>
        </template>
      </el-table-column>
      <el-table-column label="备注" prop="Rem" align="center"></el-table-column>
      <el-table-column
          label="操作"
          min-width="120px"
          align="center">
        <template slot-scope="scope">
          <el-button type="text" size="mini" @click="showRow(scope.row)">详细</el-button>
          <el-button type="text" size="mini" @click="editRow(scope.row)">编辑</el-button>
          <el-button type="text" size="mini" @click="delRow(scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
        class="mt20"
        background
        @size-change="handleSizeChange"
        @current-change="handleChange"
        :current-page="PageIndex"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="PageRows"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
    </el-pagination>
    <warehouse-application ref="warehouseApplication" dialog-status="update" :show="showWarehouseApplication" :temp="currentTemp" :c-o-d-apply-infos="currentApplyInfo" @close="handleApplicationClose"></warehouse-application>
    <cod-apply-detail :show="showDetail" :cod-object="currentRow" @close="handleDetailClose"></cod-apply-detail>
  </div>
</template>

<script>
import {delCodSKUApply, getCodSKUApply, getCodSKUApplyDetail} from "@/api/cod";
import CodApplyDetail from "@/views/member/cod/CodApplyDetail.vue";
import WarehouseApplication from "@/views/member/cod/WarehouseApplication.vue";
export default {
  data() {
    return {
      PageIndex: 1,
      showDetail: false,
      showWarehouseApplication: false,
      currentRow: {},
      currentTemp: {},
      currentApplyInfo: [],
      PageRows: 10,
      ruleForm: {},
      warehouseOptions: [],
      query: {
        dateRange: []
      },
      total: 0,
      listData: [],
      loading: false
    }
  },
  components: {
    CodApplyDetail,
    WarehouseApplication
  },
  computed: {
  },
  created() {
    this.getWareHouse()
    this.getData()
  },
  methods: {
    handleDetailClose() {
      this.showDetail = false
    },
    handleApplicationClose() {
      this.showWarehouseApplication = false
      this.getData()
    },
    handleSizeChange(val) {
      this.PageRows = val
      this.PageIndex = 1;
      this.getData()
    },
    handleChange(current) {
      this.PageIndex = current;
      this.getData()
    },
    showRow(row) {
      const data = {
        Code: row.OrderCode
      }
      getCodSKUApplyDetail(data).then(response => {
        const { Code, Msg, Data } = response
        if (Code === 200) {
          this.currentRow = Data
          this.showDetail = true
        } else {
          this.$message.warning(Msg)
        }
      })
    },
    getData() {
      const data = {
        PageIndex: this.PageIndex,
        PageRows: this.PageRows,
        StartTime: this.query.dateRange && this.query.dateRange.length ? this.query.dateRange[0] : undefined,
        EndTime: this.query.dateRange && this.query.dateRange.length ? this.query.dateRange[1] : undefined,
        QueryCodeType: 15,
        QueryCodes: this.query.OrderCode ? [ this.query.OrderCode ] : undefined,
        WareHouseId: this.query.WareHouseId || undefined,
      }
      getCodSKUApply(data).then(response => {
        const { Code, Msg, Data, Total } = response
        if (Code === 200) {
          this.listData = Data
          this.total = Total
        } else {
          this.$message.warning(Msg)
        }
      })
    },
    editRow(row) {
      const data = {
        Code: row.OrderCode
      }
      getCodSKUApplyDetail(data).then(response => {
        const { Code, Msg, Data } = response
        if (Code === 200) {
          this.currentApplyInfo = Data.CODApplyInfos
          this.currentTemp = {
            Id: Data.Id,
            OrderCode: Data.OrderCode,
            WareHouseId: Data.WareHouseId,
            Rem: Data.Rem,
            CODApplyInfos: this.currentApplyInfo.map(item => {
              return {
                SKUId: item.Id,
                Quantity: item.ApplyQuantity
              }
            })
          }
          this.$nextTick(() => {
            this.$refs.warehouseApplication.init()
            this.showWarehouseApplication = true
          })
        } else {
          this.$message.warning(Msg)
        }
      })
    },
    delRow(row) {
      this.$confirm(`该操作会永久删除入仓单号为（${row.OrderCode}）的数据，是否继续？`,
          "提示",
          {
            confirmButtonText: "确认",
            cancelButtonText: "取消",
            type: 'warning'
          }
      )
          .then(() => {
            const data = [row.OrderCode]
            delCodSKUApply(data).then(response => {
              const { Code, Msg, Success } = response
              if (Code === 200 && Success) {
                this.$message.success("删除成功！！")
                this.getData()
              } else {
                this.$message.warning(Msg)
              }
            })
          })
          .catch(() => {
            this.$message.info("已取消删除")
          });
    },
    getWareHouse() {
      this.$store.dispatch('webSite/getWareHouse').then(data => {
        this.warehouseOptions = data
      })
    }
  }
}

</script>

<style scoped lang="scss">
.warehouse-container {
  padding: 20px;
  .warehouse-title {
    padding: 1.5rem 2.5rem;
    color: #225493;
    background-color: #F0F4FB;
    font-size: 1.6rem;
    margin-bottom: 1rem;
  }
  .queryItems {
    display: flex;
    flex-wrap: wrap;
    //justify-content: space-between;
    align-items: center;
  }
}
</style>